<template>
  <header>
    <h1>{{ $t("home.header.title") }}</h1>
    <h1 class="subtitle">{{ $t("home.header.subtitle") }}</h1>
  </header>
</template>

<script>
export default {
  name: 'Header'
};
</script>
